.gov-starter {
    >.djs-visual {
        >circle {
            fill: #ffffff !important;
        }
    }
}

.gov-nopriviledge {
    >.djs-visual {
        >rect {
            fill: #dc3545 !important;
        }

        >text {
            fill: #ffffff !important;
            font-weight: bolder !important;
        }
    }
}

.gov-notused {
    >.djs-visual {
        >rect {
            fill: #e0e0e0 !important;
        }

        >text {
            fill: #808080 !important;
            font-weight: bolder !important;
        }
    }
}

.gov-unknown {
    >.djs-visual {
        >rect {
            fill: #ffffff !important;
        }

        >text {
            fill: #333333 !important;
            font-weight: bolder !important;
        }
    }
}

.gov-notstart {
    >.djs-visual {
        >rect {
            fill: #ffffff !important;
        }

        >text {
            fill: #333333 !important;
            font-weight: bolder !important;
        }
    }
}

.gov-ongoing {
    >.djs-visual {
        >rect {
            fill: #3f51b5 !important;
        }

        >text {
            fill: #ffffff !important;
            font-weight: bolder !important;
        }
    }
}

.gov-delaying {
    >.djs-visual {
        >rect {
            fill: #3f51b5 !important;
        }

        >text {
            fill: #ff0000 !important;
            font-weight: bolder !important;
        }
    }
}

.gov-delayed {
    >.djs-visual {
        >rect {
            fill: #04aa6d !important;
        }

        >text {
            fill: #ff0000 !important;
            font-weight: bolder !important;
        }
    }
}

.gov-finished {
    >.djs-visual {
        >rect {
            fill: #04aa6d !important;
        }

        >text {
            fill: #ffffff !important;
            font-weight: bolder !important;
        }
    }
}