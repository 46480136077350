.authForm {
    .upper {
        .container {
            .card-header {
                background-color: rgba(173, 216, 230, 0.8);
                border: 1px solid;
                border-top: none;
                border-left: none;
                border-right: none;
            }
        }
    }

    .section-shaped {
        position: relative;
        overflow: hidden;
    }

    .section-shaped.section-hero:before {
        top: 680px;
    }

    .section-shaped .stars-and-coded {
        margin-top: 8rem;
    }

    .section-shaped .shape {
        position: absolute;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    .section-shaped .shape span {
        position: absolute;
    }

    .section-shaped .shape+.container {
        position: relative;
        height: 100%;
    }

    .section-shaped .shape.shape-skew+.container {
        padding-top: 0;
    }

    .section-shaped .shape.shape-skew+.container .col {
        margin-top: -100px;
    }

    .section-shaped .shape.shape-skew+.shape-container {
        padding-top: 18rem;
        padding-bottom: 19rem;
    }

    .section-shaped .shape-skew {
        -webkit-transform: skewY(-4deg);
        transform: skewY(-4deg);
        -webkit-transform-origin: 0;
        transform-origin: 0;
    }

    .section-shaped .shape-skew span {
        -webkit-transform: skew(4deg);
        transform: skew(4deg);
    }

    .section-shaped .shape-primary {
        background: linear-gradient(150deg, #281483 15%, #8f6ed5 70%, #d782d9 94%);
    }

    .section-shaped .shape-primary :first-child {
        background: #53f;
    }

    .section-shaped .shape-primary :nth-child(2) {
        background: #4553ff;
    }

    .section-shaped .shape-primary :nth-child(3) {
        background: #4f40ff;
    }

    .section-shaped .shape-primary :nth-child(4) {
        background: #25ddf5;
    }

    .section-shaped .shape-primary :nth-child(5) {
        background: #1fa2ff;
    }

    .section-shaped .shape-default {
        background: linear-gradient(150deg, #7795f8 15%, #6772e5 70%, #555abf 94%);
    }

    .section-shaped .shape-default :first-child {
        background: #7795f8;
    }

    .section-shaped .shape-default :nth-child(2) {
        background: #7b9aff;
    }

    .section-shaped .shape-default :nth-child(3) {
        background: #6f8ff8;
    }

    .section-shaped .shape-default :nth-child(4) {
        background: #76eea7;
    }

    .section-shaped .shape-default :nth-child(5) {
        background: #6adaff;
    }

    .section-shaped .shape-light {
        background: linear-gradient(150deg,
                shapes-light-color("step-1-gradient-bg") 15%,
                shapes-light-color("step-2-gradient-bg") 70%,
                shapes-light-color("step-3-gradient-bg") 94%);
    }

    .section-shaped .shape-light :first-child {
        background: shapes-light-color("span-1-bg");
    }

    .section-shaped .shape-light :nth-child(2) {
        background: shapes-light-color("span-2-bg");
    }

    .section-shaped .shape-light :nth-child(3) {
        background: shapes-light-color("span-3-bg");
    }

    .section-shaped .shape-light :nth-child(4) {
        background: shapes-light-color("span-4-bg");
    }

    .section-shaped .shape-light :nth-child(5) {
        background: shapes-light-color("span-5-bg");
    }

    .section-shaped .footer .column h4,
    .section-shaped .footer .column ul li,
    .section-shaped .footer .copyrights {
        color: #fff;
    }

    [class*="shadow"] {
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease;
    }

    .btn {
        position: relative;
        text-transform: uppercase;
        will-change: transform;
        letter-spacing: 0.025em;
        font-size: 0.875rem;
    }

    .btn:hover {
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
    }

    .btn:not(:last-child) {
        margin-right: 0.5rem;
    }

    .btn i:not(:first-child),
    .btn svg:not(:first-child) {
        margin-left: 0.5rem;
    }

    .btn i:not(:last-child),
    .btn svg:not(:last-child) {
        margin-right: 0.5rem;
    }

    .card {
        position: relative;
        margin-top: 20vh;
    }

    .footer {
        background: rgba(245, 245, 245, 0.6);
        //opacity: 0.4;
        position: absolute;
        padding: 0 0 0 0;
        width: 100vw;
        bottom: 0px;
    }

    .footer .col-footer .heading {
        color: #8898aa;
        letter-spacing: 0;
        font-size: 0.875rem;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    .footer .footer-link {
        color: #8898aa !important;
    }

    .footer .footer-link:hover {
        color: #525f7f !important;
    }

    .footer .copyright {
        font-size: 0.875rem;
        color: #000000;
    }

    .footer.has-cards {
        overflow: hidden;
        padding-top: 500px;
        margin-top: -420px;
        position: relative;
        background: transparent;
        pointer-events: none;
    }

    .footer.has-cards:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 600px;
        height: 2000px;
        background: #f4f5f7;
        -webkit-transform: skew(0, -8deg);
        transform: skew(0, -8deg);
    }

    .footer.has-cards .container {
        pointer-events: auto;
        position: relative;
    }

    .form-control,
    label {
        font-size: 0.875rem;
    }

    .form-control {
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease;
        border-width: 1px;
    }

    .form-control:focus::-webkit-input-placeholder {
        color: #adb5bd;
    }

    .form-control:focus::-moz-placeholder {
        color: #adb5bd;
    }

    .form-control:focus::-ms-input-placeholder {
        color: #adb5bd;
    }

    .form-control:focus::placeholder {
        color: #adb5bd;
    }

    .form-control.is-invalid,
    .form-control.is-valid {
        background-image: none;
    }

    textarea[resize="none"] {
        resize: none !important;
    }

    textarea[resize="both"] {
        resize: both !important;
    }

    textarea[resize="vertical"] {
        resize: vertical !important;
    }

    textarea[resize="horizontal"] {
        resize: horizontal !important;
    }

    .form-control-muted {
        border-color: #edf0f5;
        box-shadow: none;
    }

    .form-control-muted,
    .form-control-muted:focus {
        background-color: #edf0f5;
    }

    .form-control-alternative {
        box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
        border: 0;
        -webkit-transition: box-shadow 0.15s ease;
        transition: box-shadow 0.15s ease;
    }

    .form-control-alternative:focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }

    .form-control-lg {
        font-size: 1rem;
    }

    @media (min-width: 992px) {
        .container-lg {
            max-width: 1160px;
        }
    }

    .icon {
        width: 3rem;
        height: 3rem;
    }

    .icon i,
    .icon svg {
        font-size: 3rem-0.75;
    }

    .input-group {
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease;
    }

    .input-group,
    .input-group .form-control {
        box-shadow: none;
    }

    .input-group .form-control:not(:first-child) {
        border-left: 0;
        padding-left: 0;
    }

    .input-group .form-control:not(:last-child) {
        border-right: 0;
        padding-right: 0;
    }

    .input-group .form-control:focus {
        box-shadow: none;
    }

    .input-group .input-group-prepend {
        margin-right: 0;
    }

    .input-group .input-group-prepend .input-group-text {
        border: 1px solid;
        border-left: none;
        border-bottom: none;
        border-top: none;
    }

    .input-group-text {
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease;
    }

    .input-group-alternative {
        box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);

        border: 1px solid;
        border-radius: 5px;

        -webkit-transition: box-shadow 0.15s ease;
        transition: box-shadow 0.15s ease;
    }

    .input-group-alternative .form-control,
    .input-group-alternative .input-group-text {
        border: 0;
        box-shadow: none;
    }

    .page-item.active .page-link {
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    }

    .page-item .page-link,
    .page-item span {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }

    .section {
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .section-xl {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }

    .section-lg {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    .section-sm {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    @media (min-width: 768px) {
        .section-hero {
            min-height: 500px;
        }
    }

    p {
        font-size: 1rem;
    }

    .description {
        font-size: 0.875rem;
    }

    .heading {
        letter-spacing: 0.025em;
        font-size: 0.95rem;
        text-transform: uppercase;
        font-weight: 400;
    }

    .heading-section,
    .heading-title {
        letter-spacing: 0.025em;
        font-size: 1.375rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    .heading-section img {
        display: block;
        width: 72px;
        height: 72px;
        margin-bottom: 1.5rem;
    }

    .footer .footer-description {
        padding: 13px;
        font-size: 12px;
        line-height: 2;
        opacity: 0.5;
    }

    .footer .column h4 {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        margin: 0 0 20px;
        color: #8898aa;
    }

    @media (max-width: 767.98px) {
        footer .title {
            text-align: center !important;
        }
    }

    .text {
        position: relative;
        left: 15px;
        top: 1px;
    }

    .name {
        font-size: 1.1em;
        font-weight: 700;
        line-height: 1.15;
        max-width: 11em;
        overflow: hidden;
        padding-top: 3px;
        text-overflow: ellipsis;
    }

    @-webkit-keyframes show {

        0%,
        49.99% {
            opacity: 0;
            z-index: 1;
        }

        50%,
        to {
            opacity: 1;
            z-index: 5;
        }
    }

    @keyframes show {

        0%,
        49.99% {
            opacity: 0;
            z-index: 1;
        }

        50%,
        to {
            opacity: 1;
            z-index: 5;
        }
    }

    .card-body .card-footer {
        position: relative;
        bottom: 0;
        left: 0;
        background-color: transparent !important;
        width: 100%;
        color: #fff;
        padding: 5px 0 0 !important;
        border-top: none !important;
    }

    .card {
        margin-bottom: 30px;
        box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
        border: 0;
    }

    .card .card-title {
        margin-bottom: 0.5rem;
    }

    .card .card-footer,
    .card .card-header {
        background-color: #fff;
    }

    .card[data-header="skew"] .card-header {
        height: 100%;
        margin-bottom: 0;
        padding: 0;
    }

    .card[data-header="skew"] .card-header:after {
        content: "";
        width: 24%;
        overflow: hidden;
        position: absolute;
        left: 37%;
        top: 7%;
        height: 96%;
        background-color: #fff;
        -webkit-transform: rotate(80deg);
        transform: rotate(80deg);
        z-index: 1;
    }

    .card[data-header="skew"] .card-category {
        z-index: 2;
        position: relative;
    }

    .card[data-header="pattern"] .card-body {
        padding-top: 10rem;
    }

    @media (min-width: 576px) {
        .card-columns {
            -webkit-column-count: 1;
            -moz-column-count: 1;
            column-count: 1;
        }
    }

    @media (min-width: 768px) {
        .card-columns {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
        }
    }

    @media (min-width: 1200px) {
        .card-columns {
            -webkit-column-count: 3;
            -moz-column-count: 3;
            column-count: 3;
            -webkit-column-gap: 1.25rem;
            -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
        }
    }

    .upper {
        position: absolute;
        margin-top: -100vh;
        width: 100vw;
        height: 100vh;
        z-index: 2;
    }

    @media (min-width: 992px) {
        .page-header .container {
            padding-top: 0 !important;
        }
    }

    .page-header,
    header {
        position: relative;
    }

    .page-header {
        min-height: 100vh;
        max-height: 999px;
        padding: 0;
        overflow: hidden;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }

    .page-header>.container {
        padding-top: 12vh;
        padding-bottom: 40px;
    }

    .page-header .page-header-image {
        position: absolute;
        background-size: 80% 100%;
        background-repeat: no-repeat;
        background-position: center;

        opacity: 0.5;

        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .page-header>.content {
        margin-top: 11%;
        text-align: center;
        margin-bottom: 50px;
    }

    .page-header.header-filter {
        position: relative;
    }

    .page-header.header-filter .container {
        z-index: 2;
        position: relative;
    }

    .page-header.header-filter:before {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background-color: rgba(253, 253, 253, 0.1);
    }

    .page-header footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .page-header .container {
        height: 100%;
        z-index: 1;
    }

    .page-header .category,
    .page-header .description {
        color: #f4f5f7;
    }

    .page-header.page-header-small {
        min-height: 100vh;
        max-height: 100vh;
    }

    .page-header .title {
        margin-bottom: 15px;
    }

    .page-header .title+h4 {
        margin-top: 10px;
    }

    .page-header.header-filter:after {
        background: rgba(0, 0, 0, 0.5);
    }

    @media (max-width: 575.98px) {
        .section-info [class^="col-"]:nth-of-type(2) .info {
            margin-top: 0 !important;
        }
    }

    .nobg {
        background-color: rgba(251, 251, 251, 0.5);
    }

    .shows {
        color: rgb(0, 0, 0);
    }

    .btnborder {
        border: 0.2px solid #000000;
    }

    .btn:hover {
        background-color: rgba(252, 252, 252, 0.3);
    }
}