mat-table,
.mat-mdc-table,
[mat-table] {
    border-collapse: separate !important;
    border-spacing: 0px !important;
    vertical-align: bottom;

    .mat-header-row,
    .mat-mdc-header-row {
        height: 40px;
    }

    .mat-footer-row,
    .mat-mdc-footer-row,
    .mat-row,
    .mat-mdc-row {
        height: auto;
    }

    .mat-footer-row,
    .mat-mdc-footer-row,
    .mat-header-row,
    .mat-mdc-header-row,
    .mat-row,
    .mat-mdc-row {
        min-height: 40px !important;

        &:nth-child(odd) {
            background-color: #f6f4f0;
        }

        &:nth-child(even) {
            background-color: #fff;
        }

        &.selected {
            background-color: rgb(255 152 0 / 87%) !important;
        }

        .mat-foot-cell,
        .mat-mdc-foot-cell,
        .mat-header-cell,
        .mat-mdc-header-cell,
        .mat-cell,
        .mat-mdc-cell {
            border-width: 0px 1px 1px 0px;
        }

        .mat-foot-cell:first-child,
        .mat-mdc-foot-cell:first-child,
        .mat-header-cell:first-child,
        .mat-mdc-header-cell:first-child,
        .mat-cell:first-child,
        .mat-mdc-cell:first-child {
            border-left-width: 1px;
            min-width: 25px;
        }

        &:nth-child(1) {

            .mat-foot-cell,
            .mat-mdc-foot-cell,
            .mat-header-cell,
            .mat-mdc-header-cell,
            .mat-cell,
            .mat-mdc-cell {
                border-top-width: 1px;
            }
        }
    }

    .mat-footer-cell,
    .mat-mdc-footer-cell,
    .mat-header-cell,
    .mat-mdc-header-cell,
    .mat-cell,
    .mat-mdc-cell {
        display: table-cell !important;
        padding: 2px 10px !important;

        box-sizing: border-box !important;
        border-style: solid !important;

        vertical-align: middle !important;
        white-space: nowrap !important;
        width: fit-content !important;

        font-size: 16px !important;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    .mat-footer-cell,
    .mat-mdc-footer-cell,
    .mat-cell,
    .mat-mdc-cell {
        border-color: rgb(0 0 0 / 42%) !important;
    }

    .mat-header-cell,
    .mat-mdc-header-cell {
        border-color: rgb(0, 0, 0) !important;
        background-color: #555 !important;
        text-align: center;
        color: #fff;

        .mat-sort-header-container {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: space-between;
            align-items: center;
        }

        .mat-sort-header-content {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            flex-wrap: nowrap;
            flex-direction: row;
            flex-grow: 1;
        }

        .mat-sort-header-arrow {
            color: #fff;
        }
    }
}

.mat-horizontal-stepper-header {
    padding-right: 12px;
    padding-left: 12px;
    height: 40px;
}

.mat-horizontal-content-container {
    padding-bottom: 12px;
}

.mat-vertical-stepper-header {
    padding-bottom: 0px;
    padding-top: 0px;
    height: 40px;
}

.mat-vertical-content {
    padding: 0px;
}

.cdk-overlay-pane {
    transform: unset !important;

    [mat-select-panel],
    .mat-select-panel,
    .mat-mdc-select-panel {
        min-width: 100% !important;
        // margin-top: 25px !important;
    }
}

mat-table,
[mat-table],
.mat-mdc-table {
    &.summery {

        .mat-cell,
        .mat-mdc-cell,
        .mat-foot-cell,
        .mat-mdc-foot-cell,
        .mat-header-cell,
        .mat-mdc-header-cell {
            // text-overflow: ellipsis !important;
            // white-space: normal !important;
            // overflow: hidden !important;
            max-width: 400px !important;
            min-width: 20px !important;
        }

        .mat-header-cell,
        .mat-mdc-header-cell {
            white-space: nowrap !important;
        }

        .mat-cell .mat-mdc-cell {
            white-space: break-spaces !important;

            &.largewidth {
                min-width: 400px !important;
            }
        }
    }
}

.wrap {
    min-width: 20px;
    max-width: 150px;
    width: fit-content;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.mat-mdc-paginator {

    .mat-mdc-paginator-container {
        min-height: unset !important;
        padding: 0px !important;
        margin: 0px !important;

        .mat-paginator-page-size,
        .mat-mdc-paginator-page-size {

            .mat-paginator-page-size-label,
            .mat-mdc-paginator-page-size-label {
                margin: auto 4px !important;
            }

            .mat-paginator-page-size-select,
            .mat-mdc-paginator-page-size-select {
                // margin: 0px 4px 10px 4px; */
                margin: 0px 0px !important;
            }
        }

        .mat-form-field-infix,
        .mat-mdc-form-field-infix {
            min-height: unset !important;
            padding: 0px !important;
            margin: 0px !important;

            .mat-mdc-paginator-touch-target {
                display: none !important;
            }
        }

        .mat-mdc-form-field-subscript-wrapper {
            display: none !important;
        }
    }
}