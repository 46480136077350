$bmd-menu-line-height: 1 !default; // makes it easier to use sizes to match spec

$bmd-menu-item-min-width: 7rem !default; // Minimum width on mobile = 2 * 56dp = 112dp
$bmd-menu-item-max-width: 17.5rem !default; // Maximum width on mobile (in both portrait and landscape) = 5 * 56dp = 280dp
$bmd-menu-item-min-height: 3rem !default; // 48px

$bmd-menu-item-padding-right: 1rem !default;
$bmd-menu-item-padding-bottom: .8rem !default;
$bmd-menu-item-padding-left: 1rem !default;
$bmd-menu-item-padding-top: .8rem !default;

// md and up
$bmd-menu-item-padding-right-md: 1.5rem !default;
$bmd-menu-item-padding-left-md: 1.5rem !default;

// Menu
$bmd-menu-expand-duration: 0.3s !default;
$bmd-menu-fade-duration: 0.2s !default;