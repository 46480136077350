/* You can add global styles to this file, and also import other style files */
$jse-icons: "/assets/img/jsoneditor-icons.svg";
$material-icons-font-path: "/assets/fonts/";
$main-bg: rgb(250 250 250) !default;
$fa-font-path: "/assets/fonts";
$grid-gutter-width: 0;

@import "node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "node_modules/jsoneditor/src/scss/jsoneditor";

// @import "node_modules/bootstrap/scss/bootstrap-utilities";
// @import "node_modules/bootstrap/scss/bootstrap-reboot";
// @import "node_modules/bootstrap/scss/bootstrap-grid";
@import "node_modules/bootstrap/scss/bootstrap";

// @import "node_modules/bootstrap-material-design/scss/bootstrap-material-design";
@import "node_modules/material-icons/iconfont/material-icons";
@import "node_modules/font-awesome/scss/font-awesome";

@import "toggle-knob";
@import "auth-form";
@import "govstatus";
@import "table";
@import "core";

html {
    --mdc-checkbox-unselected-icon-color: rgb(2 47 248 / 73%);
}

body {
    background-color: $main-bg;
    overflow: hidden !important;
    max-width: 100vw !important;
    max-height: 100vh !important;
    height: 100vh !important;
    width: 100vw !important;
    margin: 0;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 1.5;
    color: rgb(82 95 127);
}

.main-bg {
    background-color: $main-bg;
}

textarea {
    min-height: 30px;
}

.djs-context-pad {
    &.open:empty {
        display: none !important;
    }
}

.djs-direct-editing-parent {
    background-color: transparent !important;
    border: unset !important;
}

.djs-context-pad.open {
    box-sizing: border-box;
    width: min-content;
    background: var(--popup-background-color);
    overflow: hidden;
    box-shadow: 0px 2px 6px var(--popup-shadow-color);
    border: solid 1px var(--popup-border-color);
    min-width: 72px;
    outline: none;
    font-size: var(--popup-font-size);

    >.group {
        padding: 5px;
    }

    >.group:not(:last-child) {
        border-bottom: 1px solid lightgray;
    }
}

.arjs-reportPage {
    >div.arjs-textBoxItem {
        display: none !important;

        >span {
            display: none !important;
        }
    }
}

.card-frame {
    border: 1px solid rgb(0 0 0 / 20%) !important;
    box-shadow: unset !important;
    border-radius: 4px;
}

ngx-contextmenu,
.ngx-contextmenu {
    >ul {
        >li {
            >span {
                padding: unset !important;
            }
        }
    }
}

ngb-progressbar {
    >.progress-bar {
        overflow: visible !important;
    }
}

mat-checkbox,
[mat-checkbox],
.mat-checkbox,
.mat-mdc-checkbox {
    position: relative !important;

    >.mdc-form-field,
    >.mat-checkbox-layout {
        position: relative !important;
        padding: 0px !important;
        margin: 0px !important;

        >.mdc-checkbox {
            position: relative !important;
            margin: 0px !important;
            padding: 0px !important;
            height: 32px !important;
            width: 32px !important;
            flex: unset !important;

            >* {
                position: absolute !important;
                transform: unset !important;
                height: 100% !important;
                width: 100% !important;
                margin: 0px !important;
                bottom: auto !important;
                right: auto !important;
                left: 0px !important;
                top: 0px !important;
            }

            >.mat-mdc-checkbox-touch-target {
                padding: 0px !important;
            }

            >input {
                padding: 0px !important;
            }

            >.mdc-checkbox__ripple {
                padding: 0px !important;
            }

            >.mdc-checkbox__background {
                padding: 0px !important;
                margin: 25% !important;
                width: 50% !important;
                height: 50% !important;
            }

            >[mat-ripple],
            >.mat-ripple {
                padding: 0px !important;
            }
        }
    }
}

mat-chip-list.nowrap,
.mat-mdc-chip-set.nowrap,
.mat-mdc-chip-grid.nowrap {

    >.mat-chip-list-wrapper,
    >.mdc-evolution-chip-set__chips {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        >.mat-chip,
        >.mat-mdc-chip,
        >.mat-mdc-chip-row {
            word-break: keep-all;
        }
    }
}

.mat-chip-list-wrapper,
.mdc-evolution-chip-set__chips {
    margin: 0px !important;
}

button:focus {
    outline: none !important;
}

.mat-dialog-title,
.mat-mdc-dialog-title,
[mat-dialog-title] {
    margin-top: -20px !important;
    padding: 0 12px 0px 9px !important;

    &:before {
        height: 0px !important;
    }
}

.mat-dialog-resizable {
    position: absolute !important;

    mat-dialog-title,
    .mat-mdc-dialog-title,
    [mat-dialog-title] {
        cursor: move;
    }

    mat-dialog-container,
    .mat-mdc-dialog-container,
    [mat-dialog-container] {
        position: absolute !important;
        overflow: auto;
        resize: both;
    }
}

mat-dialog-content,
.mat-dialog-content,
.mat-mdc-dialog-content,
[mat-dialog-content] {
    max-height: 100% !important;
}

.mat-dialog-maximized {
    width: 100vw !important;
    height: 100vh !important;

    mat-dialog-container,
    .mat-mdc-dialog-container,
    [mat-dialog-container] {
        transform: none !important;
        height: 100% !important;
        width: 100% !important;

        .mdc-dialog__container {
            .mat-mdc-dialog-surface {
                border-radius: 0px !important;
            }
        }
    }
}

// override style from libs
mat-menu-panel,
.mat-menu-panel,
.mat-mdc-menu-panel {
    max-width: calc(100vw - 48px) !important;
}

.mat-chip,
.mat-mdc-chip {

    .mat-chip-remove,
    .mat-mdc-chip-remove,
    .mat-chip-trailing-icon,
    .mat-mdc-chip-trailing-icon {
        margin-left: auto !important;
    }
}

.mat-slide-toggle,
.mat-mdc-slide-toggle {

    &:not(.mat-checked),
    &:not(.mat-mdc-slide-toggle-checked) {

        .mdc-switch__handle::after,
        .mat-slide-toggle-bar::after {
            content: "否";
        }
    }

    &.mat-checked,
    &.mat-mdc-slide-toggle-checked {

        .mdc-switch__handle::before,
        .mat-slide-toggle-bar::before {
            content: "是";
        }
    }
}

nav[mat-tab-nav-bar],
nav.mat-tab-nav-bar,
nav.mat-mdc-tab-nav-bar {
    bottom: -3px;

    .mat-tab-header-pagination,
    .mat-mdc-tab-header-pagination {
        height: 36px;
        bottom: 0px;
    }

    .mat-tab-links,
    .mat-tab-list,
    .mat-mdc-tab-links,
    .mat-mdc-tab-list {
        display: flex;
        align-items: flex-end;
    }

    .mat-tab-link,
    .mat-mdc-tab-link {
        height: 32px;
        bottom: 0px;
    }

    mat-ink-bar,
    [mat-ink-bar],
    .mat-ink-bar,
    .mat-mdc-ink-bar {
        height: 1px;
    }

    .mat-mdc-tab-link.mdc-tab--active,
    .mat-tab-label-active {
        border-color: rgb(63 81 181) !important;
        color: rgb(68 157 72) !important;
        text-decoration: none !important;
        opacity: 1 !important;
    }
}

.mat-tab-body-wrapper,
.mat-mdc-tab-body-wrapper {
    flex: auto;
}

mat-tree,
[mat-tree],
.mat-tree {

    ul,
    li {
        margin-top: 0;
        margin-bottom: 0;
        list-style-type: none;
    }

    >mat-tree-node,
    >[mat-tree-node],
    >.mat-tree-node {
        padding-left: 40px !important;
    }

    div[role="group"] {

        >mat-tree-node,
        >[mat-tree-node],
        >.mat-tree-node {
            padding-left: 40px !important;
        }
    }

    mat-tree-node,
    [mat-tree-node],
    .mat-tree-node {

        >mat-checkbox,
        >[mat-checkbox],
        >.mat-checkbox,
        >.mat-mdc-checkbox {

            >.mdc-form-field,
            >.mat-checkbox-layout {
                margin: auto !important;
            }
        }
    }

    mat-nested-tree-node,
    [mat-nested-tree-node],
    .mat-nested-tree-node {
        padding-left: 0px !important;

        div[role="group"] {
            padding-left: 40px !important;
            align-items: flex-start;
            flex-direction: column;
            flex-wrap: nowrap;
            display: flex;
        }
    }
}

mat-progress-bar,
[mat-progress-bar],
.mat-progress-bar,
.mat-mdc-progress-bar {
    height: 30px;
}

.mat-card-header-text,
.mat-mdc-card-header-text {
    width: 100% !important;
    margin: 0px !important;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    display: flex;
}

.mat-card-header-text,
.mat-mdc-card-header-text {

    >.mat-card-title,
    >.mat-mdc-card-title {
        width: 100% !important;
        margin: 0px !important;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        display: flex;
    }
}

mat-button,
[mat-button],
.mat-button,
.mat-mdc-button,
mat-icon-button,
[mat-icon-button],
.mat-icon-button,
.mat-mdc-icon-button,
mat-stroked-button,
[mat-stroked-button],
.mat-stroked-button,
.mat-mdc-stroked-button,
mat-flat-button,
[mat-flat-button],
.mat-flat-button,
.mat-mdc-flat-button {
    line-height: inherit !important;
    padding: 3px 8px !important;
    min-width: auto !important;

    height: 35px !important;
}

mat-icon-button,
[mat-icon-button],
.mat-icon-button,
.mat-mdc-icon-button {
    padding: 0px !important;
    width: 40px !important;
}

mat-radio-button,
[mat-radio-button],
.mat-radio-button,
.mat-mdc-radio-button {

    ~mat-radio-button,
    ~[mat-radio-button],
    ~.mat-radio-button,
    ~.mat-mdc-radio-button {
        margin-left: 16px;
    }
}

.cdk-drop-list-dragging {

    cdkdrag,
    [cdkdrag],
    .cdk-drag {
        &:not(.cdk-drag-placeholder) {
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }
    }
}

.cdk-drag-preview {
    border: none;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}



/*
*::-webkit-scrollbar {
  background-color: #f5f5f5;
  height: 6px;
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 217, 255, 0.3);
  border-radius: 10px;
}
*/
.app-action-bar {
    height: 45px !important;
    border-top: 1px solid rgb(211 211 211) !important;
    justify-content: center !important;
    flex-direction: row !important;
    align-items: center !important;
    flex-wrap: nowrap !important;
    display: flex !important;
}

.app-card-pic {
    height: 120px;

    .mat-card-image {
        width: 100px;
        height: 100%;

        background-position: center;
        background-repeat: no-repeat;
        background-attachment: unset;
        -webkit-background-size: contain;
        background-size: contain;
    }
}

.app-card-file {
    height: 120px;

    .mat-card-image {
        width: 100px;
        height: 100%;

        background-position: center;
        background-repeat: no-repeat;
        background-attachment: unset;
        -webkit-background-size: contain;
        background-size: contain;
    }
}

.app-card-problem {
    width: 100%;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.flex-none {
    -ms-flex: 0 0 auto !important;
    flex: 0 0 auto !important;
}

.top-unset {
    top: unset !important;
}

.bottom-unset {
    bottom: unset !important;
}

.start-unset {
    left: unset !important;
}

.end-unset {
    right: unset !important;
}

.topstart-unset {
    top: unset !important;
    left: unset !important;
}

.topend-unset {
    top: unset !important;
    right: unset !important;
}

.bottomstart-unset {
    bottom: unset !important;
    left: unset !important;
}

.bottomend-unset {
    bottom: unset !important;
    right: unset !important;
}

@mixin v($sizes) {
    @each $s in $sizes {
        .max-vw-#{$s} {
            max-width: #{$s}vw !important;
        }

        .max-vh-#{$s} {
            max-height: #{$s}vh !important;
        }

        .max-w-#{$s} {
            max-width: #{$s}#{"%"} !important;
        }

        .max-h-#{$s} {
            max-height: #{$s}#{"%"} !important;
        }

        .min-vw-#{$s} {
            min-width: #{$s}vw !important;
        }

        .min-vh-#{$s} {
            min-height: #{$s}vh !important;
        }

        .min-w-#{$s} {
            min-width: #{$s}#{"%"} !important;
        }

        .min-h-#{$s} {
            min-height: #{$s}#{"%"} !important;
        }

        .vw-#{$s} {
            width: #{$s}vw !important;
        }

        .vh-#{$s} {
            height: #{$s}vh !important;
        }

        .w-#{$s} {
            width: #{$s}#{"%"} !important;
        }

        .h-#{$s} {
            height: #{$s}#{"%"} !important;
        }

        .start-#{$s} {
            left: #{$s}#{"%"} !important;
        }

        .end-#{$s} {
            right: #{$s}#{"%"} !important;
        }

        .top-#{$s} {
            top: #{$s}#{"%"} !important;
        }

        .bottom-#{$s} {
            bottom: #{$s}#{"%"} !important;
        }
    }
}

@include v((0, 1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
        55, 60, 65, 70, 75, 80, 85, 90, 95, 100));