.card-plain {
    background: transparent;
    box-shadow: none;

    .card-header:not(.card-avatar) {
        margin-left: 0;
        margin-right: 0;
    }

    .card-body {
        padding-left: 5px;
        padding-right: 5px;
    }

    .card-header-image {
        margin: 0 !important;
        border-radius: $border-radius-large;

        img {
            border-radius: $border-radius-large;
        }
    }

    .card-footer {
        padding-left: 5px;
        padding-right: 5px;
        background-color: transparent;
    }
}