.toggle {
    display: block;
    text-align: center;
    user-select: none;
}

.toggle--checkbox {
    display: none;
}

.toggle--btn {
    display: block;
    margin: 0 auto;
    font-size: 1.4em;
    transition: all 350ms ease-in;
}

.toggle--btn:hover {
    cursor: pointer;
}

.toggle--btn,
.toggle--btn:before,
.toggle--btn:after,
.toggle--checkbox,
.toggle--checkbox:before,
.toggle--checkbox:after,
.toggle--feature,
.toggle--feature:before,
.toggle--feature:after {
    transition: all 250ms ease-in;
}

.toggle--btn:before,
.toggle--btn:after,
.toggle--checkbox:before,
.toggle--checkbox:after,
.toggle--feature:before,
.toggle--feature:after {
    content: "";
    display: block;
}

.toggle--knob {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: flex-start center;
    align-items: flex-start;
}

.toggle--knob .toggle--btn {
    position: relative;
    width: 90px;
    height: 25px;
    font-family: "PT Sans", Sans Serif;
    text-transform: uppercase;
    color: #fff;
    background: #a4bf4d;
    box-shadow: inset 0 20px 40px -10px #7b9d25;
    border-radius: 40px;
}

.toggle--knob .toggle--btn:before {
    display: block;
    position: absolute;
    top: 50%;
    left: 67%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #f1f1f1;
    text-indent: -100%;
}

.toggle--knob .toggle--checkbox:not(:checked)+.toggle--btn:before {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    background-color: rgb(250 250 250);
}

.toggle--knob .toggle--checkbox:checked+.toggle--btn:before {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    background-color: rgb(250 250 250);
}

.toggle--knob .toggle--feature {
    position: relative;
    display: block;
    overflow: hidden;
    height: 25px;
    text-shadow: 0 1px 2px #666;
    font-size: 16px;
}

.toggle--knob .toggle--feature:before,
.toggle--knob .toggle--feature:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.toggle--knob .toggle--feature:before {
    content: attr(data-label-on);
    left: 20%;
}

.toggle--knob .toggle--feature:after {
    content: attr(data-label-off);
    right: -60%;
}

.toggle--knob .toggle--checkbox:not(:checked)+.toggle--btn {
    background: #a2a2a2;
    box-shadow: inset 0 20px 40px -10px #7e7e7e;
}

.toggle--knob .toggle--checkbox:not(:checked)+.toggle--btn:before {
    left: 0;
}

.toggle--knob .toggle--checkbox:not(:checked)+.toggle--btn .toggle--feature:before {
    left: -60%;
}

.toggle--knob .toggle--checkbox:not(:checked)+.toggle--btn .toggle--feature:after {
    right: 20%;
}