.wrapper {
    position: relative;
    height: 100vh;
    top: 0;
}

.sidebar {
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: $white-color;
    @include shadow-big();

    .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid\9;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }

    &[data-background-color="black"] {
        background-color: #191919;
    }

    .logo-img {
        width: 35px;
        display: block;
        max-height: 30px;
        margin-left: 13px;
        margin-right: 15px;

        img {
            width: 35px;
            top: 16px;
            position: absolute;
        }
    }

    .sidebar-wrapper {
        position: relative;
        overflow: auto;
        z-index: 4;
        background-color: whitesmoke;

        .dropdown .dropdown-backdrop {
            display: none !important;
        }

        .navbar-form {
            border: none;
            box-shadow: none;

            .input-group {
                font-size: 1.7em;
                height: 36px;
                width: 78%;
                padding-left: 17px;
            }
        }

        >.nav,
        .user .user-info {
            [data-toggle="collapse"]~div>ul>li>a {
                span {
                    display: inline-block;
                    @extend .animation-transition-general;
                }

                .sidebar-normal {
                    margin: 0;
                    position: relative;
                    transform: translateX(0px);
                    opacity: 1;
                    white-space: nowrap;
                    display: block;
                }

                .sidebar-mini {
                    text-transform: uppercase;
                    width: 30px;
                    margin-right: 15px;
                    text-align: center;
                    letter-spacing: 1px;
                    position: relative;
                    float: left;
                    display: inherit;
                }

                i {
                    font-size: 17px;
                    line-height: 20px;
                    width: 26px;
                }
            }
        }
    }

    .logo-tim {
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img {
            width: 60px;
            height: 60px;
        }
    }

    .nav {
        margin-top: 20px;
        display: block;

        .caret {
            margin-top: 13px;
            position: absolute;
            right: 6px;
        }

        li {
            >a {

                &:hover,
                &:focus {
                    background-color: transparent;
                    outline: none;
                }
            }

            &:first-child>a {
                margin: 0 15px;
            }

            &:hover>a,
            & .dropdown-menu a:hover,
            & .dropdown-menu a:focus,
            &.active>[data-toggle="collapse"] {
                background-color: rgba(200, 200, 200, 0.2);
                color: $black-color;
                box-shadow: none;
            }

            &.active>[data-toggle="collapse"] {
                i {
                    color: #a9afbb;
                }
            }

            &.active>a,
            &.active>a i {
                color: $white-color;
            }

            &.separator {
                margin: 15px 0;

                &:after {
                    width: calc(100% - 30px);
                    content: "";
                    position: absolute;
                    height: 1px;
                    left: 15px;
                    background-color: rgba(180, 180, 180, 0.3);
                }

                &+li {
                    margin-top: 31px;
                }
            }
        }

        p {
            margin: 0;
            line-height: 30px;
            font-size: 14px;
            position: relative;
            display: block;
            height: auto;
            white-space: nowrap;
        }

        i {
            font-size: 24px;
            float: left;
            margin-right: 15px;
            line-height: 30px;
            width: 30px;
            text-align: center;
            color: #a9afbb;
        }
    }

    .nav li a,
    .nav li .dropdown-menu a {
        margin: 10px 15px 0;
        border-radius: $border-radius-base;
        color: $black-color;
        padding-left: 10px;
        padding-right: 10px;
        text-transform: capitalize;
        font-size: $font-paragraph - 1;
        padding: 10px 15px;
    }

    .logo {
        padding: 15px 0px;
        margin: 0;
        display: block;
        position: relative;
        z-index: 4;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 15px;
            height: 1px;
            width: calc(100% - 30px);
            background-color: rgba(180, 180, 180, 0.3);
        }

        p {
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
        }

        .simple-text {
            text-transform: uppercase;
            padding: $padding-small-vertical $padding-zero;
            display: inline-block;
            font-size: 18px;
            color: $black-color;
            white-space: nowrap;
            font-weight: $font-weight-default;
            line-height: 30px;
            overflow: hidden;
            text-align: center;
            display: block;
        }
    }

    .logo-tim {
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img {
            width: 60px;
            height: 60px;
        }
    }

    &[data-background-color="black"] {
        @include sidebar-background-color($gray-base, $white-color);

        .nav li .dropdown-menu .dropdown-item {
            color: $white-color;
        }
    }

    &[data-color="purple"] {
        @include set-background-color-button($brand-primary);
    }

    &[data-color="azure"] {
        @include set-background-color-button($brand-info);
    }

    &[data-color="green"] {
        @include set-background-color-button($brand-success);
    }

    &[data-color="orange"] {
        @include set-background-color-button($brand-warning);
    }

    &[data-color="danger"] {
        @include set-background-color-button($blue-900);
    }

    &[data-color="rose"] {
        @include set-background-color-button($brand-rose);
    }

    &[data-color="white"] {
        @include set-background-color-button($white-color);
        @include sidebar-active-color($black-color);
    }

    &[data-background-color="red"] {
        @include sidebar-background-color($brand-danger, $white-color);

        .user,
        .logo,
        .nav li.separator {
            &:after {
                background-color: rgba(255, 255, 255, 0.3);
            }
        }

        .nav {

            li:hover:not(.active)>a,
            li.active>[data-toggle="collapse"] {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }

    &[data-image]:after,
    &.has-image:after {
        opacity: 0.77;
    }
}

.main-panel {
    position: relative;
    @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    .main-content {
        margin-top: 70px;
        padding: 30px 15px;
    }

    .footer {
        border-top: 1px solid #e7e7e7;
    }

    .navbar {
        margin-bottom: 0;
    }

    .header {
        margin-bottom: 30px;

        .title {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}

.sidebar,
.main-panel,
.sidebar-wrapper {
    -webkit-transition-property: top, bottom, width;
    transition-property: top, bottom, width;
    -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
    transition-duration: 0.2s, 0.2s, 0.35s;
    -webkit-transition-timing-function: linear, linear, ease;
    transition-timing-function: linear, linear, ease;
    -webkit-overflow-scrolling: touch;
}

.visible-on-sidebar-regular {
    display: inline-block !important;
}

.visible-on-sidebar-mini {
    display: none !important;
}

@media (min-width: 991px) {
    .sidebar-mini {
        .visible-on-sidebar-regular {
            display: none !important;
        }

        .visible-on-sidebar-mini {
            display: inline-block !important;
        }

        .main-panel {
            width: $sidebar-mini-width;
        }

        .sidebar {
            display: block;
            font-weight: 200;
            z-index: 9999;

            .logo {
                a.logo-normal {
                    opacity: 0;
                }
            }

            .sidebar-wrapper {

                >.nav [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
                .user .user-info [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
                .user .user-info>a>span,
                >.nav li>a p {
                    opacity: 0;
                }
            }
        }

        .sidebar:hover {
            .logo {
                a.logo-normal {
                    opacity: 1;
                }
            }

            .sidebar-wrapper {

                >.nav li>a p,
                >.nav [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
                .user .user-info [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
                .user .user-info>a>span {
                    opacity: 1;
                }
            }
        }
    }

    .sidebar {
        .nav .nav-item {
            &.active-pro {
                position: absolute;
                width: 100%;
                bottom: 13px;
            }
        }
    }
}